import React, { useState } from "react";
import "./ImageZoom.css";

const ProductZoomMagnifier = ({ imageSrc, altText }) => {
  const [zoomData, setZoomData] = useState({
    x: 0,
    y: 0,
    visible: false,
  });

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100; // Percentage X
    const y = ((e.clientY - rect.top) / rect.height) * 100; // Percentage Y

    setZoomData({
      x,
      y,
      visible: true,
    });
  };

  const handleMouseLeave = () => {
    setZoomData({ ...zoomData, visible: false });
  };

  return (
    <div className="zoom-wrapper">
      {/* Main Image */}
      <div className="main-product-image"
>
        <div
          className="image-container"
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          <img src={imageSrc} alt={altText} className="main-product-image" />
        </div>
      </div>
      {/* Zoomed Image */}
      {zoomData.visible && (
        <div
          className="zoomed-image"
          style={{
            backgroundImage: `url(${imageSrc})`,
            backgroundPosition: `${zoomData.x}% ${zoomData.y}%`,
            backgroundSize: "200%", // Zoom level
          }}
        ></div>
      )}
    </div>
  );
};

export default ProductZoomMagnifier;