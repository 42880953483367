import { useState } from "react";
import "./SizeSelector.css";
const SizeSelector = ({ fieldData, size, handleSizeChange }) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filtered options based on search input
  const filteredOptions = fieldData
    ?.filter(option => option.BoxType === 59)
    ?.filter(option =>
      option.OptionName.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <div className="size-selector-container">
      <select
        className="size-options-dropdown"
        value={size}
        onChange={(e) => {
          const selectedOption = filteredOptions.find(option => option.FeildId === Number(e.target.value));
          if (selectedOption) {
            handleSizeChange(
              selectedOption.FeildId,
              selectedOption.OptionName,
              selectedOption.VariationName
            );
          }
        }}
      >
        <option value="" disabled>Select Size</option>
        {filteredOptions.map((sizeOption) => (
          <option key={sizeOption.FeildId} value={sizeOption.FeildId}>
            {sizeOption.OptionName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SizeSelector;
